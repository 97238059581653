<template>
  <div class="content">
    <PageHeader :breadcrumbLinks="breadcrumbLinks" :title="'SMS'" :subtitle="'Templates'" />
    <!-- Templates SMS -->
    <section class="page-content container-fluid">
      <div class="row">
        <div class="col-lg-12">
          <div class="col-lg-6 template-filters">
            <div class="filter-search">
              <form @submit.prevent="search">
                <div class="input-group">
                  <input
                    placeholder=""
                    type="text"
                    class="form-control"
                    :v-model="searchQuery"
                    ref="searchInput"
                  />
                  <div class="input-group-prepend">
                    <button type="button" class="btn icon_btn" @click="search">
                      <span class="material-symbols-outlined"> search </span>
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <div class="input-group">
              <div class="input-group-prepend">
                <button
                  v-modal="{ target: 'create-template' }"
                  class="btn btn-yup-purple-outline"
                  style="display: flex"
                >
                  <span class="material-symbols-outlined"> add </span
                  ><span>{{ $t('sms.templates.lbl-create') }}</span>
                  <!-- Criar novo template -->
                </button>
              </div>
            </div>
          </div>
        </div>
        <div v-if="!templates.length && fetched" class="col-lg-12">
          <!-- <i
            class="fas fa-envelope font-size-80"
            v-tooltip.top="$t('sms.templates.lbl-tooltip')"
          ></i> -->
          <LottieAnimNoData />
          <h5 class="card-title text-center m-t-20">
            {{ $t('sms.templates.none') }}
          </h5>
          <!-- Nenhum template cadastrado -->
          <p class="text-center">{{ $t('sms.templates.lbl-register') }}</p>
          <!-- Cadastre seu primeiro template. -->
        </div>
        <!-- Templates Column -->
        <div class="col-lg-6" v-if="fetched && templates.length">
          <div class="col-lg-12 d-flex align-items-center">
            <div class="control-group opt-2">
              <label
                class="control control-checkbox select-all-checkbox"
                for="customCheckTAll"
              >
                <input
                  v-model="selectAllTemplates"
                  type="checkbox"
                  class="custom-control-input"
                  id="customCheckTAll"
                />
                <div class="control_indicator"></div>
                <span>{{ $t('generic-str.all') }}</span>
              </label>
            </div>
            <button
              class="btn btn-yup-purple-outline btn-delete"
              :class="{
                'qt-loader qt-loader-mini qt-loader-right': isSending,
              }"
              @click="deleteAll"
              v-bind:disabled="selectedTemplates.length == 0 || isSending">
              <span class="material-symbols-outlined">delete</span><!-- Excluir -->
            </button>
          </div>
          <div
            class="card card-templates"
            v-for="(template, i) in templates"
            :key="template.id"
          >
            <div class="card-header">
              <div class="container-fluid">
                <div class="row">
                  <div class="col-10 template-head-infos">
                    <div class="control-group opt-2">
                      <label
                        class="control control-checkbox"
                        :for="'template-check' + i"
                      >
                        <input
                          v-model="selectedTemplates"
                          type="checkbox"
                          class="custom-control-input"
                          :id="'template-check' + i"
                          :value="template"
                        />
                        <div class="control_indicator"></div>
                      </label>
                    </div>
                    <span class="mr-4"
                      >{{ $t('send-component.title') }}:
                      <span>{{ template.name }}</span></span
                    >
                    <span class="max-200"
                      >ID: <span>{{ template.id }}</span></span
                    >
                  </div>
                  <div class="col template-opt">
                    <span :id="'visibility-n-' + i" class="material-symbols-outlined visibility-icon" @click="showPreview(i, template)">visibility</span>
                    <dropdown>
                      <template v-slot:text>
                        <span class="material-symbols-outlined title">
                          more_vert
                        </span>
                      </template>
                      <template #items>
                        <button
                          v-modal="{
                            target: 'template-modal',
                            data: template,
                          }"
                          class="dropdown-item"
                        >
                          {{ $t('generic-str.edit') }}
                        </button>
                        <button
                          class="dropdown-item"
                          @click="
                            deleteTemplate(
                              template.id,
                              template.name,
                              template.body,
                              template.created_at,
                            )
                          "
                        >
                          {{ $t('generic-str.remove') }}
                        </button>
                      </template>
                    </dropdown>
                    <!-- <div class="dropdown">
                      <a
                        role="button"
                        class="dropdown-action"
                        :to="'#menu-dropdown-' + i"
                        type="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        <i class="icon dripicons-gear text-success"></i>
                      </a>
                      <div
                        class="dropdown-menu dropdown-menu-right"
                        :id="'menu-dropdown-' + i"
                        x-placement="bottom-end"
                        style=""
                      >
                        <a
                          class="dropdown-item"
                          v-modal="{
                            target: 'template-modal',
                            data: template,
                          }"
                          >{{ $t('generic-str.edit') }}</a
                        >
                        <a
                          class="dropdown-item"
                          @click="
                            deleteTemplate(
                              template.id,
                              template.name,
                              template.body,
                              template.created_at,
                            )
                          "
                          >{{ $t('generic-str.remove') }}</a
                        >
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="card-body">
              <p class="card-text">{{ template.body }}</p>
            </div>
            <div class="card-footer">
              <span class="material-icons-outlined">sync</span>
              <time>{{ (template.updated_at || template.created_at) | displayDateHour }}</time>
            </div>
          </div>
        </div>
        <!-- Templates Column -->
        <!-- <div v-else class="qt-block-ui relative" style="padding: 120px" /> -->
        <div v-if="!fetched" class="loading min-h-300">
          <LoadingAnim />
        </div>
        <!-- Preview Column -->
        <div v-if="fetched && templates.length" class="col-lg preview-div">
          <div class="preview-wrapper">
            <div class="card card-preview">
              <!-- <div v-if="!this.fetchPreview" class="card-body">
                <div class="lazy-wrapper mb-4">
                  <template v-for="(item, index) in items">
                    <span :key="index.value" class="mb-0 animated-background" :class="`w-${item.value}`"></span>
                  </template>
                </div>
                <div class="lazy-wrapper">
                  <template v-for="(item, index) in items">
                    <span :key="index.value" class="mb-0 animated-background" :class="`w-${item.value}`"></span>
                  </template>
                </div>
              </div> -->
              <div class="card-body">
                <!-- <div class="img-wrapper" v-if="this.waba_img">
                  <img :src="this.waba_img" alt="">
                </div> -->
                <p class="card-text" v-if="this.temp_title">
                  {{this.temp_title}}
                </p>
                <p v-else class="card-text">
                  Olá cliente 🤩 Voce ganhou um Cupom de desconto valido ate o
                  dia xxx.
                </p>
                <p class="card-text footer-item" v-if="this.temp_desc">
                  {{this.temp_desc}}
                </p>
                <p v-else class="card-text footer-item">
                  Clique em um dos '<span class="material-symbols-outlined visibility-icon">visibility</span>' para pré-visualizar o seu template 😀
                </p>
                <!-- <div class="preview-message-btns">
                  <span type="button" class="btn-link-preview" v-if="this.waba_btn_type == 'PHONE_NUMBER'"><i class="fas fa-phone"></i> {{this.waba_btn_text}}</span>
                  <span type="button" class="btn-link-preview" v-if="this.waba_btn_type == 'URL'"><i class="fas fa-link"></i> {{this.waba_btn_text}}</span>
                  <span type="button" class="btn-link-preview" v-if="this.waba_btn_type == 'QUICK_REPLY'">{{this.waba_btn_text}}</span>
                </div> -->
              </div>
            </div>
            <img class="phone-img" src="@/assets/phone.svg" alt="Phone Background" />
          </div>
        </div>
        <!--/Preview Column -->
        <br />
        <pagination :lastPage="pages" @change="fetch" />
      </div>
    </section>
    <create-template-modal id="create-template" @submit="fetch(1)" />
    <edit-template-modal id="template-modal" @submit="fetch(1)" />
  </div>
</template>

<script>
// @ is an alias to /src
import PageHeader from '@/components/rebranding/PageHeader.vue';
import CreateTemplateModal from '@/components/rebranding/sms/templates/CreateTemplateModal.vue';
import EditTemplateModal from '@/components/rebranding/sms/templates/EditTemplateModal.vue';
import SmsService from '@/services/sms.service';
import Swal from 'sweetalert2';
import Pagination from '@/components/Pagination.vue';
import Dropdown from '@/components/Dropdown.vue';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';
import LottieAnimNoData from '@/components/rebranding/animLottie/NoData.vue';

export default {
  name: 'SmsTemplates',
  components: {
    PageHeader,
    CreateTemplateModal,
    EditTemplateModal,
    Pagination,
    Dropdown,
    LoadingAnim,
    LottieAnimNoData,
  },
  data() {
    return {
      breadcrumbLinks: {
        1: '/sms/reports/stat',
        2: '/sms/templates',
      },

      searchQuery: null,
      isSending: false,
      fetched: false,
      fetchPreview: false,
      temp_title: '',
      temp_desc: '',
      templates: [],
      form: {
        page: 1,
      },
      pages: 1,
      selectedTemplates: [],
    };
  },
  computed: {
    selectAllTemplates: {
      get() {
        if (this.templates) {
          return this.selectedTemplates.length === this.templates.length;
        }
        return false;
      },
      set(value) {
        const selected = [];

        if (value) {
          this.templates.forEach((client) => {
            selected.push(client);
          });
        }

        this.selectedTemplates = selected;
      },
    },
  },
  filters: {
    displayDateHour(date) {
      return new Date(date).toLocaleString();
    },
  },
  created() {
    this.fetch(1);
  },
  methods: {
    search() {
      this.fetched = false;
      const word = this.$refs.searchInput.value;
      // console.log('Query: ', word);
      let result = null;
      SmsService.getTemplates(this.form).then(
        (response) => {
          this.fetched = true;
          this.templates = response.data;
          // console.log('Templates: ', this.templates);
          result = this.templates.filter((item) => word.toLowerCase().split(' ').every((v) => item.name.toLowerCase().includes(v)));
          // console.log('Return result: ', result);
          this.templates = result;
          /* if (this.searchQuery) {
            return this.templates.filter(
              (response) => this.searchQuery.toLowerCase().split(' ').every((v) => response.name.toLowerCase().includes(v)),
            );
          } */
        },
        (error) => {
          this.content = error;
        },
      );
    },
    showPreview(previewIndex, data) {
      this.fetchPreview = false;
      const previewValue = previewIndex;
      // const img = document.getElementById(`img-${previewValue}`);

      // this.shuffle();

      console.log('Preview: ', data);

      const elems = document.querySelectorAll('.visibility-icon');
      [].forEach.call(elems, (el) => {
        el.classList.remove('active');
      });
      const visibilityIcon = document.getElementById(`visibility-n-${previewIndex}`);
      visibilityIcon.classList.add('active');

      this.fetchPreview = true;
      /* if (data.components[3] !== undefined) {
        this.waba_img = data.components[3].example.header_handle[0];
      } else {
        this.waba_img = '';
      } */
      if (data.name.length) {
        this.temp_title = data.name;
      }
      if (data.body.length) {
        this.temp_desc = data.body;
      }
      /* if (data.components[0].buttons.length) {
        if (data.components[0].buttons.type === 'URL') {
          this.waba_btn_type = data.components[0].buttons[0].type;
          this.waba_btn_url = data.components[0].buttons[0].url;
          this.waba_btn_text = data.components[0].buttons[0].text;
        } else {
          // Reset
          this.waba_btn_url = '';

          this.waba_btn_type = data.components[0].buttons[0].type;
          this.waba_btn_text = data.components[0].buttons[0].text;
        }
      } */
      /* WhatsAppService.getTemplates(this.form).then(
        (response) => {
          this.fetchPreview = true;

          const templateResponse = response.data.data.waba_templates[previewValue];
          // this.templates = response.data.data.waba_templates;
          console.log('Preview body: ', templateResponse.components[2].text);
          if (templateResponse.components[2].text.length) {
            this.waba_body = templateResponse.components[2].text;
          }
          if (templateResponse.components[1].text.length) {
            this.waba_footer = templateResponse.components[1].text;
          }
        },
        (error) => {
          this.content = error;
        },
      ); */
    },
    fetch(page) {
      this.form.page = page;
      this.fetched = false;
      SmsService.getTemplates(this.form).then(
        (response) => {
          this.fetched = true;
          this.templates = response.data;
          console.log('Teste: ', response.data);
          this.pages = response.last_page;
        },
        (error) => {
          this.content = error;
        },
      );
    },
    deleteAll() {
      Swal.fire({
        title: this.$t('template-component.remove'),
        text: this.$t('template-component.remove-text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: this.$t('generic-str.yes'),
      }).then((result) => {
        if (result.value) {
          const toDelete = [];
          this.selectedTemplates.forEach((item) => {
            toDelete.push(item.id);
          });
          this.isSending = true;
          SmsService.deleteTemplates({ ids: toDelete }).then(
            () => {
              this.$toast.show({
                title: this.$t('generic-str.success'),
                content: this.$t('template-component.deleted'),
                type: 'success',
              });
              this.fetch(1);
              this.isSending = false;
              this.selectedTemplates = [];
            },
            (error) => {
              console.log(error);
              this.$toast.show({
                title: `${this.$t('sms.infos.filters.status.lbl-error')} ${
                  error.code
                }`,
                content: error.response.data.message,
                type: 'danger',
              });
              this.isSending = false;
            },
          );
        }
      });
    },
    deleteTemplate(id, name, body, createdAt) {
      const blockquoteStyle =
        'padding: 1rem;font-size: 0.85rem;background-color: #f2f2f2;font-style: italic;line-height: 25px;text-align: start;width: 90%;margin: 1rem auto 0;border-radius: 6px;';
      Swal.fire({
        title: 'Remover template',
        html: `Tem certeza que gostaria de remover o template:
        <blockquote style="${blockquoteStyle}">
          <strong>ID:</strong> ${id}<br>
          <strong>Nome do template:</strong> ${name}<br>
          <strong>Mensagem:</strong> ${body}<br>
          <strong>Criado em:</strong> ${createdAt}<br>
        </blockquote>`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.isConfirmed) {
          this.fetchedItens = false;
          this.isSending = true;
          Promise.all([SmsService.deleteTemplate(id)])
            .then(
              (responses) => {
                console.log(responses);
                this.$toast.show({
                  title: 'Sucesso',
                  content: 'Template removido',
                  type: 'success',
                });
                this.fetch(1);
                this.isSending = false;
                this.fetchTemplates(1);
              },
              (error) => {
                this.content = error;
              },
            )
            .finally(() => {
              this.fetchedItens = true;
            });
        }
      });
    },
  },
};
</script>

<style scoped lang="scss">
.btn i {
  line-height: 1;
  color: inherit;
}

/* Rebranding */
.select-all-checkbox {
  width: fit-content;
  display: flex;
  gap: 10px;
  margin: 1rem;
}

.template-opt {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.template-head-infos {
  display: flex;
  align-items: center;
  gap: 10px;
}

.template-head-infos span > span {
  font-weight: 400;
}

.card-templates .card-text {
  width: fit-content;
  background-color: #f2ecfd;
  padding: 4px 10px;
}

.darkmode .card-templates .card-text {
  background-color: var(--background-3);
}

.card-templates .card-footer {
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: right;

  span, time {
    vertical-align: middle;
  }
}

.card-templates .card-body {
  padding-top: 10px;
  padding-bottom: 10px;
}

.card-templates .card-footer {
  time, span {
    margin-left: auto;
    color: var(--gray-font-color);
  }

  time {
    font-size: .82rem;
  }
}

.filter-search {
  max-width: 400px;
  width: inherit;

  input {
    border-right-color: var(--form-input) !important;
  }
}

.filter-search .btn {
  padding: 0;
}

.filter-search ~ .input-group {
  justify-content: flex-end;
}

.icon_btn {
  display: flex;
  align-items: center;
  background-color: #fff;
  border: 1px solid var(--form-border);
  border-left-color: var(--form-input);
  border-radius: 0.25rem;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  padding-right: 15px !important;

  span {
    color: var(--gray-font-color);
  }
}

.darkmode .icon_btn {
  background-color: var(--form-input);
}

.template-filters {
  display: flex;
  gap: 15px;
}

/* Phone preview */
.preview-div {
  display: flex;
  // position: relative;
  /* align-items: center; */
  position: sticky;
  top: 100px;
  height: fit-content;
}

.preview-wrapper {
  width: 100%;
  height: fit-content;
}

.phone-img {
  margin-left: auto;
  width: 20rem;
  height: fit-content;
}

.visibility-icon {
  color: var(--gray-font-color);
  cursor: pointer;
  transition: .5s;
}

.visibility-icon.active {
  color: var(--clr-yup-purple) !important;
}

.card-preview {
  .footer-item {
    font-size: 1.15rem;

    span.material-icons-outlined {
      transform: translateY(5px);
    }
  }
}

@media (min-width: 768px) {
  ::v-deep label {
    font-size: 1rem !important;
  }
  .phone-img {
    position: absolute;
    right: 0;
    padding-right: 3rem;
    // margin-top: 10%; // Position-sticky
    z-index: -1;
  }
  .preview-wrapper {
    /* position: sticky;
    top: 100px; */
    display: flex;
    align-items: center;
    height: 512px;
  }
  .card-preview {
    position: absolute;
    width: 410px;
    // top: 200px;
    right: 20%;
    // margin-top: 4rem;
    margin-bottom: 0 !important;
  }
  .card-preview .card-body {
    padding: 1rem 1rem 2rem;

    .img-loading {
      width: 378px;
      height: 189px;
    }
  }
}
.sort-group, .create-group {
  width: auto;
}

/* btn Preview style */
.preview-message-btns {
  border-top: 1px solid #dadde1;
  margin-left: 5px;
  margin-right: 5px;
  margin-top: 10px;
  text-align: center;
  font-size: 14px;
  padding: 5px;
  color: #00a5f4;
  font-size: 14px;
  line-height: 20px;
}
.preview-message-btns i {
  color: #00a5f4;
}
.preview-message-btns span {
  display: block;
  margin-bottom: 8px;
}

.btn-delete {
  padding: 5px;
}
</style>
